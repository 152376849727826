<template>
    <div class="card col-md-8 col-lg-6 col-xl-5 col-xl2-4 mx-auto px-0">
        <div class="card-body">
            <h4 class="my-5 lead font-weight-medium text-center">Password change</h4>
            <form id="reset-password-form" class="mb-5" @submit.prevent="handleSubmit">
                <Input class="mb-4"
                       class-name="form-control-lg"
                       name="password"
                       type="password"
                       label="Password"
                       placeholder="Enter your password (6 or more characters)"
                       autocomplete="new-password"
                       @change="handleChange"
                       :value="password"
                       :v="$vServer.password || $v.password"/>
                <Input class="mb-4"
                       class-name="form-control-lg"
                       name="password_confirmation"
                       type="password"
                       label="Confirm password"
                       placeholder="Confirm password"
                       autocomplete="new-password"
                       @change="handleChange"
                       :value="password_confirmation"
                       :v="$vServer.password_confirmation || $v.password_confirmation"/>
                <div class="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
                    <button type="submit" class="btn btn-primary btn-block btn-lg py-2 px-5">Change password</button>
                </div>
            </form>
            <hr class="my-5">
            <p class="text-center mt-2">
                <router-link :to="{name: 'login'}">Back to the login page</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import Input from "@/components/form/Input";
import {scrollToError} from "@/helpers";
import {RESET_PASSWORD_URL} from "@/config/api";
import {auth_route} from "@/config/auth";

export default {
    name: "ResetPasswordForm",
    data: () => ({
        submitted: false,
        email: null,
        token: null,
        password: null,
        password_confirmation: null,
    }),
    created() {
        this.email = this.$route.query.email || null;
        this.token = this.$route.params.token || null;
    },
    validations: {
        password: {
            required,
            minLength: minLength(6),
        },
        password_confirmation: {
            required,
            minLength: minLength(6),
            sameAs: sameAs('password')
        },
    },
    components: {Input},
    computed: {
        ...mapGetters({
            '$vServer': 'serverNotice/errors'
        }),
    },
    methods:{
        handleChange({name, value}) {
            if (this[name] !== value) {
                // clear server errors
                this.$store.dispatch('serverNotice/clearErrors');
                this[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                await this.resetPassword();
            }
        },
        async resetPassword() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();

                const {email, token, password, password_confirmation} = this;
                const {data} = await this.$http.post(RESET_PASSWORD_URL, {email, token, password, password_confirmation}, {
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', data);
                this.$router.push({name: auth_route})
            } catch (e){
                const {data, status} = e.response;
                if (!data.status)
                    data.status = status;
                this.$store.dispatch('serverNotice/setNotice', data);
                this.resetForm();
                this.$root.$preloader.finish();
            }
        },
        resetForm() {
            this.password = null;
            this.password_confirmation = null;
            this.submitted = false;
            this.$v.$reset();
        },
    }
}
</script>

<style lang="scss">
#reset-password-form{
    .form-control-lg{
        @include font-size($input-font-size);
        height: 50px;
    }
}
</style>
