<template>
    <PublicLayout id="app-auth">
        <Logo class="align-items-center my-2 my-md-5 my-lg-7"/>
        <ResetPasswordForm />
        <CertifiedLogos class="certified-logos my-5 py-5 flex-column"/>
    </PublicLayout>
</template>

<script>
import Logo from "@/components/Logo";
import CertifiedLogos from "@/components/CertifiedLogos";
import ResetPasswordForm from "@/shared/authentication/ResetPasswordForm";
import {name} from "@/config/app";

export default {
    name: "ResetPassword",
    middleware: ['guest','check-reset-password'],
    meta: {
        title: `Reset Password - ${name}`
    },
    components: {CertifiedLogos, Logo, ResetPasswordForm},
}
</script>

<style lang="scss">
#app-auth {
    .navbar-brand {
        svg {
            width: 250px;
            height: auto;

            @include media-breakpoint-down('sm') {
                width: 200px;
            }
        }
    }

    .certified-logos {
        margin-bottom: 1rem;

        strong {
            margin-bottom: 1rem;
        }

        img {
            &:first-child {
                margin-left: 0 !important;
            }
        }
    }
}
</style>